import type { FrontendComponentsMapping } from '../../core/js/react-provider.js';

import { hydrateReactComponents } from '../../core/js/react-provider.js';

const frontendComponents: FrontendComponentsMapping = {
  Large: async () =>
    (
      await import(
        '../../../src/aftenposten/views-react/PodcastTeaser/Variants/Large/Large.js'
      )
    ).Large,
  TTSPlaylistTeaser: async () =>
    (
      await import(
        '../../../src/aftenposten/views-react/PodcastTeaser/Variants/TTSPlaylist/TTSPlaylistTeaser.js'
      )
    ).TTSPlaylistTeaser,
  FirstStepGuide: async () =>
    (
      await import(
        '../../../src/aftenposten/views-react/FirstStepGuide/index.js'
      )
    ).FirstStepGuide,
  FollowedContentWidget: async () =>
    (
      await import(
        '../../../src/aftenposten/views-react/FollowedContentWidget/index.js'
      )
    ).FollowedContentWidget,
  MorningBrief: async () =>
    (await import('../../../src/aftenposten/views-react/MorningBrief/index.js'))
      .MorningBrief,
  VideoPlayer: async () =>
    (
      await import(
        '../../../src/aftenposten/views-react/VideoPlayer/VideoPlayer.js'
      )
    ).VideoPlayer,
  NewsletterWidget: async () =>
    (
      await import(
        '../../../src/aftenposten/views-react/NewsletterWidget/NewsletterWidget.js'
      )
    ).NewsletterWidget,
  Oppsummert: async () =>
    (
      await import(
        '../../../src/aftenposten/views-react/Oppsummert/Oppsummert.js'
      )
    ).Oppsummert,
  Oppsummert2024: async () =>
    (
      await import(
        '../../../src/aftenposten/views-react/Oppsummert/Oppsummert-2024.js'
      )
    ).Oppsummert2024,
  ReelsSlider: async () =>
    (await import('../../../src/aftenposten/views-react/Reels/ReelsSlider.js'))
      .ReelsSlider,
};

window.addEventListener('DOMContentLoaded', async () => {
  await hydrateReactComponents(frontendComponents);
});
